<template>
  <div>
    <!-- Banner -->
    <banner
      :back-image="data.bannerImage"
      height="600"
      :title="$t('cookies.title')"
    />

    <!-- Text -->
    <div class="container my-5">

      <p>
        {{ $t('cookies.text0') }}

        <a href="home">{{ limmatWeb }}</a>

        {{ $t('cookies.text0_1') }}
      </p>

      <p>
        {{ $t('cookies.text01') }}
      </p>

      <h1 class="mt-5 mb-3">
        {{ $t('cookies.subtitle1') }}
      </h1>

      <p> {{ $t('cookies.text11') }} </p>

      <p> {{ $t('cookies.text12') }} </p>

      <h1 class="mt-5 mb-3">
        {{ $t('cookies.subtitle2') }}
      </h1>

      <p> {{ $t('cookies.text21') }} </p>

      <p class="mb-3">
        {{ $t('cookies.text22') }}
      </p>

      <span>
        <strong><u style="text-transform: uppercase">{{ $t('cookies.caption') }}</u></strong>
      </span>

      <vue-good-table
        class="mt-3 mb-4"
        :rows="items"
        :columns="columns"
        :sort-options="{
          enabled: false,
        }"
      />

      <p> {{ $t('cookies.text23') }} </p>

      <p> {{ $t('cookies.text24') }} </p>

      <p>
        {{ $t('cookies.text25') }}

        <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es">{{ $t('cookies.developers') }}</a>
      </p>

      <p> {{ $t('cookies.text26') }} </p>

      <h1 class="mt-5">
        {{ $t('cookies.subtitle3') }}
      </h1>

      <p> {{ $t('cookies.text3') }} </p>

      <h1 class="mt-5">
        {{ $t('cookies.subtitle4') }}
      </h1>

      <p> {{ $t('cookies.text4') }} </p>

      <p>

        <b> Firefox </b>

        <ul>

          <li>
            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">{{ $t('cookies.firefox') }}</a>
          </li>

        </ul>

      </p>

      <p>

        <b> Chrome </b>

        <ul>

          <li>
            <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">{{ $t('cookies.chrome') }}</a>
          </li>

        </ul>

      </p>

      <p>

        <b> Safari </b>

        <ul>

          <li>
            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">{{ $t('cookies.safari') }}</a>
          </li>

        </ul>

      </p>

      <p>

        <b> Internet Explorer</b>

        <ul>

          <li>
            <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">{{ $t('cookies.explorer') }}</a>
          </li>

        </ul>

      </p>

      <p>

        <b> Microsoft Edge </b>

        <ul>

          <li>
            <a href="https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy">{{ $t('cookies.edge') }}</a>
          </li>

        </ul>

      </p>

      <p> {{ $t('cookies.text46') }} </p>

      <h1 class="mt-5">
        {{ $t('cookies.subtitle5') }}
      </h1>

      <p> {{ $t('cookies.text5') }} </p>

      <h1 class="mt-5">
        {{ $t('cookies.subtitle6') }}
      </h1>

      <p>
        {{ $t('cookies.text6') }}

        <a
          style="color:#ff8f00;"
          @click="mailto()"
        >
          <span>social@limmat-group.com</span>
        </a>

      </p>

    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import MyData from 'Data/cookies.json'

export default {
  name: 'Cookies',
  components: {
    Banner,
    VueGoodTable,
  },
  data() {
    return {
      data: MyData.data,
      limmatWeb: 'www.limmat-group.com',
      columns: [{
        label: this.$t('cookies.text2t1'),
        field: 'c1',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: this.$t('cookies.text2t2'),
        field: 'c2',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: this.$t('cookies.text2t3'),
        field: 'c3',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: this.$t('cookies.text2t4'),
        field: 'c4',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      ],
      items: [
        {
          c1: this.$t('cookies.text2t11'),
          c2: this.$t('cookies.text2t21'),
          c3: this.$t('cookies.text2t31'),
          c4: this.$t('cookies.text2t41'),
        },
        {
          c1: this.$t('cookies.text2t12'),
          c2: this.$t('cookies.text2t22'),
          c3: this.$t('cookies.text2t32'),
          c4: this.$t('cookies.text2t42'),
        },
        {
          c1: this.$t('cookies.text2t13'),
          c2: this.$t('cookies.text2t23'),
          c3: this.$t('cookies.text2t33'),
          c4: this.$t('cookies.text2t43'),
        },
      ],

    }
  },
  methods: {
    mailto() {
      window.open('mailto:social@limmat-group.com', '_blank')
    },
  },
}
</script>

<style scoped>

  b {
    font-weight: bold;
  }

  span {
    font-size: 19px;
  }

  @media (max-width: 700px){
    h1 {
      font-size: 40px;
    }

    span {
      font-size: 16px;
    }
  }

</style>
